import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Box, Grommet, Heading } from 'grommet';
import React, { Suspense, lazy } from 'react';

import Contact from './Contact';
import Intro from './Intro';

const About = lazy(() => import('./About'));
const LineQuartz = lazy(() => import('./LineQuartz'));
const Schedule = lazy(() => import('./Schedule'));

const theme = {
  global: {
    font: {
      family: 'Roboto',
      size: '14px',
      height: '20px',
    },
    colors: {
      brand: '#4AB9BE',
      dark: '#59C6C6',
      light: '#A0EDEF',
      focus: '#A0EDEF',
    },
  },
};

export default function App() {
  return (
    <BrowserRouter>
      <Grommet theme={theme} full>
        <Route path="/" component={Intro} />
        <Suspense
          fallback={
            <Box fill align="center">
              <Heading>
                <span className="emoji" role="img" aria-label="seed" aria-hidden="false">
                  🌱
                </span>
              </Heading>
            </Box>
          }
        >
          <Switch>
            <Route path="/linequartz" component={LineQuartz} />
            <Route path="/about" component={About} />
            <Route path="/schedule" component={Schedule} />
          </Switch>
        </Suspense>
        <Contact />
      </Grommet>
    </BrowserRouter>
  );
}
