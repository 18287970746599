import { Box, Button, Heading, Image } from 'grommet';
import { Link } from 'react-router-dom';
import React, { useMemo } from 'react';

import Logo from './Logo';

export default function Intro({ location }) {
  const isHome = useMemo(() => location.pathname === '/', [location]);

  return (
    <Box style={{ transition: 'background 0.3s, padding 0.3s' }}>
      <Box justify="center" align="center">
        <Link to="/">
          <Logo baseline size={360} />
        </Link>
      </Box>
      <Box justify="center" align="center">
        {isHome && <Heading textAlign="center">Un accompagnement thérapeutique</Heading>}
        <Box direction="row" justify="center" align="center" wrap>
          <Box pad="medium">
            <Link to="linequartz">
              <Button
                style={{
                  fontWeight: 700,
                  background: 'rgba(255,255,255,0.4)',
                  backdropFilter: 'blur(3px)',
                }}
                label="Protocoles LineQuartz"
              />
            </Link>
          </Box>
          <Box pad="medium">
            <Link>
              <Button
                style={{
                  fontWeight: 700,
                  background: 'rgba(255,255,255,0.4)',
                  backdropFilter: 'blur(3px)',
                }}
                label="Groupe de parole"
              />
            </Link>
          </Box>
          <Box pad="medium">
            <Link to="about">
              <Button
                style={{
                  fontWeight: 700,
                  background: 'rgba(255,255,255,0.4)',
                  backdropFilter: 'blur(3px)',
                }}
                label="À propos"
              />
            </Link>
          </Box>
          <Box pad="medium">
            <Link to="schedule">
              <Button
                style={{
                  fontWeight: 700,
                  background: 'rgba(255,255,255,0.4)',
                  backdropFilter: 'blur(3px)',
                }}
                label="Prendre rendez-vous"
              />
            </Link>
          </Box>
        </Box>
        {isHome && (
          <Box height="medium" pad="medium">
            <Image fit="cover" src="gems.jpg" />
          </Box>
        )}
      </Box>
    </Box>
  );
}
