import { Box, Button, Heading, Paragraph } from 'grommet';
import React from 'react';

export default function Contact() {
  return (
    <Box align="center" pad="medium">
      <Heading level={2}>Contact</Heading>
      <Box direction="row" flex wrap justify="center">
        <Box align="center" pad="medium">
          <Paragraph>07 67 18 67 11</Paragraph>
          <Button
            style={{
              whiteSpace: 'nowrap',
            }}
            href="tel:07 67 18 67 11"
            label="Me joindre par téléphone"
            target="_blank"
          />
        </Box>
        <Box align="center" pad="medium">
          <Paragraph>contact@therava.page</Paragraph>
          <Button
            style={{
              whiteSpace: 'nowrap',
            }}
            href="mailto:contact@therava.page"
            label="M'envoyer un mail"
            target="_blank"
          />
        </Box>
        <Box align="center" pad="medium">
          <Paragraph>Soorts-Hossegor, Landes (40)</Paragraph>
          <Button
            style={{
              whiteSpace: 'nowrap',
            }}
            href="https://goo.gl/maps/iWWMDCEvX1RuQF5K8"
            label="Voir sur Google Maps"
            target="_blank"
          />
        </Box>
      </Box>
    </Box>
  );
}
